import { ref } from '@vue/composition-api'
import store from '@/store'
import menuStoreModule from '../menuStoreModule'

export default function useMenusForm() {
  const MENU_APP_STORE_MODULE_NAME = 'app-menu'

  // Register module
  if (!store.hasModule(MENU_APP_STORE_MODULE_NAME)) store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule)

  const blankMenuData = {
    operation: 'C',
    description: null,
    descriptionAr: null,
    resource: null,
    route: null,
    defaultAction: null,
    moduleId: null,
    isSubmenu: false,
    parentMenuId: null,
    showInSidebar: 0,
    status: 'C',
  }

  const menuData = ref(JSON.parse(JSON.stringify(blankMenuData)))

  return {
    menuData,
  }
}
