<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(createMenu)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <menus-form :menu-data="menuData" />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import MenusForm from '../menus-form/MenusForm.vue'
import useMenusForm from '../menus-form/useMenusForm'
import useModules from '../../modules/useModules'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    MenusForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const { menuData } = useMenusForm()

    const { fetchModules } = useModules()

    fetchModules()

    async function createMenu() {
      const response = await store.dispatch('app-menu/procMenu', menuData.value)
      if (response?.data?.status) {
        this.$router.push({ name: 'system-settings-modules-list' })
      }
    }

    return {
      //  Data
      menuData,

      //  Action
      createMenu,
    }
  },
}
</script>
